.leave-review span {
  font-size: 15px;
  line-height: 22px;
  color: #8e8e93;
}
.leave-review form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-top: 32px;
}
.leave-review form textarea {
  width: 100%;
  padding: 15px 12px;
  border-radius: 13px;
  border: 1px solid transparent;
  font-size: 16px;
  color: #4b4846;
  transition: border-color 0.3s ease;
}
.leave-review form textarea::placeholder {
  color: #8e8e93;
}
.leave-review form textarea:focus {
  border-color: #1893d2;
  outline: none;
}
.leave-review form .review-length-error {
  text-align: center;
}
.leave-review form .star-ratings {
  align-self: center;
  margin-bottom: 16px;
}
.leave-review .review-button {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: 700;
  text-decoration: none;
  color: #aadfee;
  background-color: transparent;
  border: 1px solid #aadfee;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 32px;
}
.leave-review .review-button.active {
  background-color: #1893d2;
  border: none;
  color: #ffffff;
}
.leave-review .review-button.bold {
  color: #057697;
  border: 1px solid #1893d2;
}
.leave-review .review-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 0.7;
}
.leave-review .favorite-vendor {
  display: flex;
  align-items: center;
  gap: 8px;
}
.leave-review .favorite-vendor img {
  width: 32px;
  height: 32px;
}
.leave-review .favorite-vendor span {
  font-size: 15px;
  font-weight: 500;
}
.leave-review .smile-img {
  margin-top: 64px;
}
.leave-review .thx {
  font-size: 17px;
  font-weight: 600;
  color: #96c13d;
  margin-top: 32px;
}
.leave-review .thx-text {
  font-size: 17px;
  font-weight: 500;
  color: #4b4846;
  text-align: center;
  max-width: 322px;
  margin-top: 16px;
  margin-bottom: 32px;
}


