.transaction-card {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background: white;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
}

.transaction-info {
  display: flex;
  flex-direction: column;
}

.transaction-title {
  color: #1893d2;
  font-size: 15px;
  line-height: 22px;
  font-weight: 700;
  margin-bottom: 4px;
}

.transaction-subtitle {
  color: #4b4846;
  font-size: 15px;
  line-height: 22px;
  text-transform: capitalize;
}

.transaction-details {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  gap: 8px;
}

.transaction-date {
  color: #979797;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 10px;
}

.review-button {
  background: #96c13d;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 4px 24px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

.transactions-list__empty {
  color: #4b4846;
  font-size: 15px;
  line-height: 22px;
}


