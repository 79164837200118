.message-modal {
  margin: auto 0;
}

.message-modal__inner {
  display: flex;
  flex-direction: column;
  padding: 8px;

  span {
    color: #1893d2;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 32px;
  }

  button {
    width: 100%;
    height: 40px;
    background-color: #96c13d;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
}
