.auth-mobile-header {
    margin: 70px 0 0;

    display: flex;
    align-items: center;
    justify-content: center;

    & img {
        width: 80%;
        height: auto;
    }
}
