.modal {
    height: 100vh;
    width: 100vw;

    max-height: 100%;

    padding: 20px 0;

    display: flex;
    align-items: flex-start;
    justify-content: center;

    background-color: rgba(0, 0, 0, .2);

    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

    opacity: 0;
    pointer-events: none;
    transition: all .5s linear;

    &.active {
        opacity: 1;
        pointer-events: all;

        & div {
            height: auto;
        }
    }
}

.modal-content {
    width: 90%;
    height: auto;

    padding: 0 10px 10px;

    background-color: #fff;
    border-radius: 12px;

    overflow: auto;
    z-index: 100;

    transform: scale(.5);
    transition: all .4s linear;

    &.active {
        transform: scale(1);
    }
}
