.resend-pin {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.resend-pin p {
  color: #4b4846;
  line-height: 22px;
  font-weight: 500;
}
.resend-pin p:last-child:not(.resend-pin__timer) {
  color: #1893d2;
  text-decoration: underline;
}
.resend-pin .resend-pin__timer mark {
  color: #1893d2;
  font-weight: 600;
  background: none;
}


