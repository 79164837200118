.alert {
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
}

.alert.success {
  background-color: rgba(52, 199, 89, 0.95);
  box-shadow: 0 4px 12px rgba(52, 199, 89, 0.3);
}

.alert.error {
  background-color: rgba(255, 59, 48, 0.95);
  box-shadow: 0 4px 12px rgba(255, 59, 48, 0.3);
}
