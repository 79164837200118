.leave-review {
  span {
    font-size: 15px;
    line-height: 22px;
    color: #8e8e93;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin-top: 32px;

    textarea {
      width: 100%;
      padding: 15px 12px;
      border-radius: 13px;
      border: 1px solid transparent;
      font-size: 16px;
      color: #4b4846;
      transition: border-color 0.3s ease;

      &::placeholder {
        color: #8e8e93;
      }

      &:focus {
        border-color: #1893d2;
        outline: none;
      }
    }

    .review-length-error {
      text-align: center;
    }

    .star-ratings {
      align-self: center;
      margin-bottom: 16px;
    }
  }

  .review-button {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    font-weight: 700;
    text-decoration: none;
    color: #aadfee;
    background-color: transparent;
    border: 1px solid #aadfee;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 32px;

    &.active {
      background-color: #1893d2;
      border: none;
      color: #ffffff;
    }

    &.bold {
      color: #057697;
      border: 1px solid #1893d2;
    }

    &:disabled {
      background-color: #cccccc;
      cursor: not-allowed;
      opacity: 0.7;
    }
  }

  .favorite-vendor {
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      width: 32px;
      height: 32px;
    }

    span {
      font-size: 15px;
      font-weight: 500;
    }
  }

  .smile-img {
    margin-top: 64px;
  }

  .thx {
    font-size: 17px;
    font-weight: 600;
    color: #96c13d;
    margin-top: 32px;
  }

  .thx-text {
    font-size: 17px;
    font-weight: 500;
    color: #4b4846;
    text-align: center;
    max-width: 322px;
    margin-top: 16px;
    margin-bottom: 32px;
  }
}
