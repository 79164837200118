.pin-input {
  display: flex;
  gap: 8px;
  justify-content: center;
}

.pin-input input {
  width: 48px;
  height: 48px;
  text-align: center;
  font-size: 20px;
  color: #4B4846;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}

.pin-input input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.pin-input input:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
} 