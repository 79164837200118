.fallback {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 16px;
  height: 100vh;
  color: black;
}

.fallback__text {
  font-size: 17px;
  font-weight: 700;
  color: #8e8e93;
  text-align: center;
}

.fallback__button {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: 700;
  text-decoration: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: #1893d2;
  border: none;
  color: #ffffff;
  padding: 0 16px;
}
