@use "../../../variables/Variables";

.menu-wrapper {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
}

.menu-btn__wrapper {
    position: absolute;
    top: 0;
    left: 0;

    z-index: 1000;

    width: 100%;
    padding: 25px 30px 0;
    background-color: transparent;

    display: flex;
    justify-content: space-between;
}

.menu-btn {
    position: relative;

    width: 20px;
    height: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    z-index: 1000;

    transition: all .5s ease-in-out;
}

.menu-btn__burger {
    width: 20px;
    height: 2px;

    background-color: #4B4846;

    border-radius: 3px;

    transition: all .5s ease-in-out;

    &:before, &:after {
        position: absolute;

        content: '';

        width: 20px;
        height: 2px;

        background-color: #4B4846;

        border-radius: 3px;

        transition: all .5s ease-in-out;
    }

    &:before {
        transform: translateY(-5px);
    }

    &:after {
        transform: translateY(5px);
    }
}

.menu-btn.active .menu-btn__burger {
    transform: translateX(-50px);

    background: transparent;
}

.menu-btn.active .menu-btn__burger:before {
    transform: rotate(45deg) translate(35px, -35px);
}

.menu-btn.active .menu-btn__burger:after {
    transform: rotate(-45deg) translate(35px, 35px);
}

.menu {
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;

    color: #4B4846;
    opacity: 0;
    transition: opacity .2s linear;

    &.active {
        opacity: 1;
    }
}

.menu-navigation {
    position: absolute;
    height: 100vh;

    top: 0;
    right: 0;
    z-index: -1;

    width: 100%;

    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;

    transition: opacity .3s linear;

    &.active {
        opacity: 1;
        z-index: 10;
    }
}

.menu-m-nav {
    height: auto;
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;

    padding: 85px 30px 20px;

    background-color: #fff;
}

.nav-link {
    display: flex;
    align-items: center;

    margin: 0 0 18px;

    & div {
        margin: 0 0 0 22px;

        font-size: 15px;
        font-weight: 600;
        line-height: 22px;

        color: #4B4846;
    }

    &.activities {
        position: relative;
    }
}

.new-activities__parent {
    position: relative;
}

.new-activities {
    width: 26px;
    height: 26px;

    margin: 0;
    //padding: 0 2px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: -10px;
    right: -30px;

    background-color: rgba(255, 88, 88, 1);
    border-radius: 50%;

    font-size: 13px;
    color: #fff;
}
