.transactions-filters {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.2s linear;
  display: flex;
  align-items: center;
  justify-content: center;
}
.transactions-filters.active {
  opacity: 1;
  z-index: 1000;
}

.transactions-filters__inner {
  width: 70%;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
}

.transactions-filters__title {
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
  color: #000;
  padding: 20px 0;
}

.transactions-filters__option {
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  color: #1893d2;
  text-transform: capitalize;
  padding: 15px 0;
  border-top: 1px solid #ccc;
}
.transactions-filters__option.selected {
  background-color: #f2f2f2;
}
.transactions-filters__option.clear {
  color: red;
}


