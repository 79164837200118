.error-modal__inner {
  display: flex;
  gap: 20px;
  padding: 20px 0;
}
.error-modal__inner img {
  width: 24px;
  height: 24px;
}
.error-modal__inner div {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.error-modal__inner div span {
  color: #4b4846;
  font-weight: 700;
  margin-bottom: 8px;
}
.error-modal__inner div div {
  color: red;
  font-size: 15px;
}


