.error-modal__inner {
  display: flex;
  gap: 20px;
  padding: 20px 0;

  img {
    width: 24px;
    height: 24px;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 12px;

    span {
      color: #4b4846;
      font-weight: 700;
      margin-bottom: 8px;
    }

    div {
      color: red;
      font-size: 15px;
    }
  }
}
