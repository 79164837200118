.layout-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  line-height: 22px;
  padding-right: 32px;
  margin-bottom: 24px;
  position: relative;
}
.layout-header__back-arrow {
  padding: 8px 12px;
}
.layout-header__title {
  display: flex;
  flex-direction: column;
}
.layout-header__title span {
  color: rgb(5, 118, 151);
  font-size: 17px;
  font-weight: 700;
  text-align: center;
}
.layout-header__title span:nth-child(2) {
  font-size: 14px;
  font-weight: 400;
}
.layout-header__right {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 32px;
  min-width: 32px;
}
.layout-header:not(:has(.layout-header__back-arrow)) {
  justify-content: center;
  padding-right: 0;
}


