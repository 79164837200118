.layout {
  height: 100vh;
  background-color: #f1f0f0;
  position: relative;
  padding: 60px 0 30px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
.layout .layout-header {
  margin-left: 24px;
  margin-right: 24px;
}

.layout__inner {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px 24px 0;
  overflow-y: auto;
}


