* {
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  color: #ffffff;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.web-container {
  width: 100%;
  max-width: 1360px;
  padding: 0 15px;
  margin: 0 auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

.gm-style-cc {
  display: none;
}

.gmnoprint {
  display: none;
}

.rad-pr {
  margin: 50px 0 0;
}

.lc {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nothing-text {
  font-weight: 500;
  font-size: 25px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #B9B9B9;
}

.nothing-small-text {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #B9B9B9;
}


